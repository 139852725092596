import React from "react"

import Layout from "../components/UI/Layout"
import Seo from "../components/Seo"
import Navbar from "../components/UI/Navbar"
import SubmissionReceived from "../components/SubmissionReceived"

export default function ReviewPage() {
  return (
    <Layout>
      <Navbar />
      <Seo title="Form Submission Received" />
      <SubmissionReceived />
    </Layout>
  )
}
