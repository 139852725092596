import React, { useEffect } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import { green } from "@material-ui/core/colors"
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline"

const useStyles = makeStyles(theme => ({
  h1: {
    fontSize: "3rem",
    fontWeight: 500,
    paddingBottom: "1rem",
  },
  h2: {
    fontSize: "1.25rem",
    fontWeight: 400,
  },
}))

export default function SubmissionReceived() {
  const classes = useStyles()

  const [firstName, setFirstName] = React.useState(null)

  useEffect(() => {
    const localName = localStorage.getItem("fname")
    setFirstName(localName)
  }, [firstName])

  return (
    <Container maxWidth="md" style={{ paddingTop: "6rem" }}>
      <Grid container justifyContent="center" className={classes.container}>
        <Grid item xs={12}>
          <StaticImage
            src="../assets/images/Andreatta_Waterscapes_logo3.png"
            height={150}
            alt="Andreatta Waterscapes Logo"
            style={{ marginLeft: "auto", marginRight: "auto", display: "flex" }}
            objectFit="contain"
            imgStyle={{
              display: "flex",
              marginLeft: "auto",
              marginRighit: "auto",
            }}
            quality={90}
            placeholder="tracedSVG"
          />
        </Grid>
        <Grid item xs={12} style={{ paddingTop: "3rem" }}>
          <Typography
            variant="h1"
            className={classes.h1}
            align="center"
            gutterBottom
          >
            {firstName
              ? `Thank you for contacting us, ${firstName}!`
              : "Thank you for contacting us!"}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="h2"
            className={classes.h2}
            align="center"
            gutterBottom
          >
            We've received your submission and will be in touch soon!
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          style={{ paddingTop: "3rem", paddingBottom: "5rem" }}
        >
          <Button
            variant="contained"
            size="large"
            color="secondary"
            component={Link}
            to="/"
          >
            Go back home
          </Button>
        </Grid>
      </Grid>
    </Container>
  )
}
